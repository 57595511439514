



































































































@import '@design';

.nav-drawer-locations {
  a.v-list__tile {
    padding-left: 40px;
  }
}
